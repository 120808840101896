import { UseQueryOptions } from '@tanstack/react-query'
import { serverRepository } from '../domain'

const TELEGRAM_NEWS_QUERY_KEY = 'telegram_news'

export const telegramNewsListQuery = () =>
  ({
    queryKey: [TELEGRAM_NEWS_QUERY_KEY],
    queryFn: () => serverRepository.getNewsList(),
    staleTime: 5 * 60 * 1000,
    refetchInterval: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false
  } satisfies UseQueryOptions)
