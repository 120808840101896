import { telegramApi } from '@shared/api/server'
import { ApiError } from '@shared/errors/api.error'
import { ModelError } from '@shared/errors/model.error'
import { RepositoryError } from '@shared/errors/repository.error'
import { NEWS_COUNT } from '../server.config'
import { parseTelegramNews } from '../server.lib'
import { NewsType } from '../server.types'

export class ServerRepository {
  async getNewsList(): Promise<NewsType[]> {
    try {
      const telegramNewsResponse = await telegramApi.fetchTelegramNews({
        count: NEWS_COUNT
      })
      const data = telegramNewsResponse.map((telegramNews) =>
        parseTelegramNews(telegramNews)
      )

      return data
    } catch (error) {
      if (error instanceof ModelError) {
        console.log(
          'catch: ',
          error.errors
            ?.map((error) => `${error.path} - ${error.message}`)
            .join(',')
        )
        throw new RepositoryError(error.code, error.message)
      } else if (error instanceof ApiError) {
        throw new RepositoryError(error.code, error.message)
      } else {
        throw error
      }
    }
  }
}
