import styles from './news-item.module.css'

import cn from 'classnames'

interface NewsItemProps extends React.ComponentPropsWithoutRef<'div'> {
  news: string
  timestamp: Date
}

export const NewsItem = ({
  className,
  news,
  timestamp,
  ...rest
}: NewsItemProps) => {
  return (
    <div className={cn(styles.container, className)} {...rest}>
      <div dangerouslySetInnerHTML={{ __html: news }} />
      <span className={styles.date}>{timestamp.toLocaleDateString()}</span>
    </div>
  )
}
