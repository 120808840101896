import { ModelError } from '@shared/errors/model.error'
import { ServiceSchema } from '../service.schema'

export class ServiceModel {
  constructor(
    public readonly id: string,
    public readonly serviceName: string,
    public readonly serviceCode: string,
    public readonly serviceFivesimCode: string | null,
    public readonly availableCount: number,
    public readonly isFavorite: boolean,
    public readonly cost: number | null,
    public readonly serviceOldCode: string | null // public readonly bringTime: number
  ) {}

  static fromAPI(data: unknown): ServiceModel {
    const result = ServiceSchema.safeParse(data)
    if (!result.success) {
      throw new ModelError('INVALID_DATA_FORMAT', result.error.errors)
    }
    return new ServiceModel(
      result.data.id,
      result.data.serviceName,
      result.data.serviceCode,
      result.data.serviceFivesimCode,
      result.data.availableCount,
      result.data.isFavorite,
      result.data.cost ?? null,
      result.data.serviceOldCode
      // result.data.bringTime
    )
  }
}
