import { PAGES } from '@shared/config/constants'
import cn from 'classnames'
import { NavLink, Outlet } from 'react-router-dom'

import { PanelInner } from '@components/panel-inner'

import styles from './api-page.module.css'

export const ApiPage = () => {
  return (
    <PanelInner className={styles.container}>
      <div className={styles.tabs}>
        <NavLink
          to={PAGES.API_PAGE.V2}
          className={({ isActive }) =>
            isActive ? cn(styles.link, styles.linkActive) : styles.link
          }
          relative="route"
        >
          {'API V2'}
        </NavLink>
        <NavLink
          to={PAGES.API_PAGE.CURRENT}
          className={({ isActive }) =>
            isActive ? cn(styles.link, styles.linkActive) : styles.link
          }
        >
          {'API V1'}
        </NavLink>
        <NavLink
          to={PAGES.API_PAGE.OLD}
          className={({ isActive }) =>
            isActive ? cn(styles.link, styles.linkActive) : styles.link
          }
          relative="route"
        >
          {'API V0'}
        </NavLink>
      </div>
      <Outlet />
    </PanelInner>
  )
}
