import { ModelError } from '@shared/errors/model.error'
import { CountrySchema } from '../country.schema'

export class CountryModel {
  constructor(
    public readonly id: string,
    public readonly countryCode: string,
    public readonly countryFivesimCode: string | null,
    public readonly countryName: string,
    public readonly flagUrl: string,
    public readonly availableCount: number,
    public readonly isFavorite: boolean,
    public readonly cost: number | null
  ) {}

  static fromAPI(data: unknown): CountryModel {
    const result = CountrySchema.safeParse(data)
    if (!result.success) {
      throw new ModelError('INVALID_DATA_FORMAT', result.error.errors)
    }
    return new CountryModel(
      result.data.id,
      result.data.countryCode,
      result.data.countryFivesimCode,
      result.data.countryName,
      result.data.flagUrl,
      result.data.availableCount,
      result.data.isFavorite,
      result.data.cost ?? null
    )
  }
}
