import { Flex } from '@components/flex'
import { SkeletonBase } from '@components/skeleton-base'
import styles from './api-v2-page.module.css'

export const ApiV2PageLoader = () => {
  return (
    <Flex direction="column">
      <SkeletonBase className={styles.skeletonTitle} />
      <Flex direction="column" align="center">
        <SkeletonBase className={styles.skeletonItem} />
        <SkeletonBase className={styles.skeletonItem} />
        <SkeletonBase className={styles.skeletonItem} />
        <SkeletonBase className={styles.skeletonItem} />
        <SkeletonBase className={styles.skeletonItem} />
        <SkeletonBase className={styles.skeletonItem} />
        <SkeletonBase className={styles.skeletonItem} />
        <SkeletonBase className={styles.skeletonItem} />
      </Flex>
    </Flex>
  )
}
