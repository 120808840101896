export const PAGES = {
  ROOT_PAGE: '/',
  MENU_PAGE: 'menu',
  BUY_PAGE: 'buy',
  DEPOSIT_PAGE: 'deposit',
  PK_PAGE: {
    INDEX: 'pk',
    PROFILE_PAGE: 'profile',
    TOPUP_HISTORY_PAGE: 'topup',
    WITHDRAW_HISTORY_PAGE: 'withdraw',
    DEVELOPERS_PAGE: 'developers',
    SETTINGS: 'settings'
  },
  NUMBER_HISTORY_PAGE: 'history/number',
  CONTACTS_PAGE: 'contacts',
  API_PAGE: {
    INDEX: 'documentation',
    CURRENT: 'current',
    OLD: 'old',
    V2: 'v2'
  },
  INSTRUCTION_PAGE: 'instructions',
  AUTH_PAGE: 'signin',
  REGISTER_PAGE: 'signup',
  VERIFICATION_MAIL_PAGE: 'prove/user/mail',
  FORGET_PASSWORD_PAGE: 'forget',
  SUCCESS_DEPOSIT_PAGE: 'deposit/success',
  FAILED_DEPOSIT_PAGE: 'deposit/failed',
  DEVELOPERS_PAGE: 'developers',
  NOT_FOUND_PAGE: '404',
  FORBIDDEN_PAGE: 'forbidden',
  TERMS_PAGE: 'terms',
  REFERALS_PAGE: 'referals'
}

export const URL_PARAMS = {
  SEARCH: 'q',
  INTERVAL: 'interval',
  OPERATOR_CODE: 'opc',
  MODE: 'mode',
  COUNTRY: 'cn',
  SERVICE: 'sn',
  PAGE: 'page',
  PAYMENT_SERVICE: 'ps',
  PAYMENT_METHOD: 'pm',
  PAYMENT_VALUE: 'value',
  PAYMENT_CURRENCY: 'currency',
  STATUS: 'status',
  START_DATE: 'sdt',
  END_DATE: 'edt',
  SORT_BY: 'sortBy',
  SORT: 'sort',
  VERIFICATION_CODE: 'code',
  ONLY_ARCHIVE: 'archive'
}

export const WORK_MODE = {
  COUNTRY: undefined,
  SERVICE: '2'
}

export const STORAGE_KEYS = {
  FAVORITE_SERVICES: 'fss',
  FAVORITE_COUNTRIES: 'fcs',
  FAVORITE_METHODS: 'fm'
}
